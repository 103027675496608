import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Heading } from "../../Components/Heading";
import { SearchBox } from "../../Components/SearchBox";
import { styled } from "@mui/material/styles";
import { Container } from "@mui/system";
import { IApiResponse, useFetch } from "../../Utils/hooks/useFetch";

const KbFilters = styled("div")(({ theme }) => ({
  flexGrow: 1,
  paddingBlockStart: "3rem",
  "& .search-box": {
    maxWidth: "48.25rem",
    margin: "0 auto",
    "& .heading": {
      marginBottom: "1.875rem",
    },
  },
}));

const Landing = () => {
  const [techTags, setTechTags] = useState([]);
  const [businessTags, setBusinessTags] = useState([]);
  const [techTagsError, setTechTagsError] = useState<boolean>(false);
  const [businessTagsError, setBusinessTagsError] = useState<boolean>(false);
  const [techTagsLoading, setTechTagsLoading] = useState<boolean>(false);
  const [businessTagsLoading, setBusinessTagsLoading] = useState<boolean>(false);
  const {
    data: customerTechTagsFirst,
    loading: firstTechTagsLoading,
    error: firstTechTagsError,
  }: IApiResponse = useFetch("customer-tech-tags?pagination[page]=1&pagination[pageSize]=100&&populate=*");

  const {
    data: customerTechTagsSecond,
    loading: secondTechTagsLoading,
    error: secondTechTagsError,
  }: IApiResponse = useFetch("customer-tech-tags?pagination[start]=101&pagination[limit]=100&&populate=*");

  const {
    data: customerBusinessTagsFirst,
    loading: businessTagsLoadingFirst,
    error: businessTagsErrorFirst,
  }: IApiResponse = useFetch("customer-business-tags?pagination[start]=0&pagination[limit]=100&&populate=*");
  const {
    data: customerBusinessTagsSecond,
    loading: businessTagsLoadingSecond,
    error: businessTagsErrorSecond,
  }: IApiResponse = useFetch("customer-business-tags?pagination[start]=101&pagination[limit]=100&&populate=*");

  const sortTags = (array: any) => {
    return array?.sort((a: any, b: any) => b.attributes.customers.data.length - a.attributes.customers.data.length);
  };

  useEffect(() => {
    let technologyTags: any = [];
    if (customerTechTagsFirst && customerTechTagsSecond) {
      setTechTagsLoading(false);
      technologyTags = [...customerTechTagsFirst];
    }
    if (technologyTags?.length) {
      const sortTechnologyTags = sortTags(technologyTags);
      sortTechnologyTags.length = 25;
      setTechTags(sortTechnologyTags);
    }
  }, [customerTechTagsFirst]);

  useEffect(() => {
    let industryTags: any = [];
    if (customerBusinessTagsFirst && customerBusinessTagsSecond) {
      setBusinessTagsLoading(false);
      industryTags = [...customerBusinessTagsFirst, ...customerBusinessTagsSecond];
    }

    if (industryTags?.length) {
      const sortIndustryTags = sortTags(industryTags);
      sortIndustryTags.length = 25;
      setBusinessTags(sortIndustryTags);
    }
  }, [customerBusinessTagsFirst, customerBusinessTagsSecond]);

  useEffect(() => {
    if (firstTechTagsLoading && secondTechTagsLoading) {
      setTechTagsLoading(true);
      setTechTagsError(false);
    }
  }, [firstTechTagsLoading, secondTechTagsLoading]);

  useEffect(() => {
    if (businessTagsLoadingFirst && businessTagsLoadingSecond) {
      setBusinessTagsLoading(true);
    }
  }, [businessTagsLoadingFirst, businessTagsLoadingSecond]);

  useEffect(() => {
    if (firstTechTagsError || secondTechTagsError) {
      setTechTagsError(true);
      setTechTagsLoading(false);
    }
  }, [firstTechTagsError, secondTechTagsError]);

  useEffect(() => {
    if (businessTagsErrorFirst || businessTagsErrorSecond) {
      setBusinessTagsError(true);
      setBusinessTagsLoading(false);
    }
  }, [businessTagsErrorFirst, businessTagsErrorSecond]);

  return (
    <KbFilters>
      <Container>
        <Box className="search-box">
          <Heading variant="h1" align="center" title="Hello, how may I help you ?" className="heading" />
          <SearchBox
            id="search"
            labelText="Search"
            onlySearchIcon={false}
            isTechTagsLoading={techTagsLoading}
            isBusinessTagsLoading={businessTagsLoading}
            techTagsError={techTagsError}
            businessTagsError={businessTagsError}
            techTags={techTags}
            businessTags={businessTags}
          />
        </Box>
      </Container>
    </KbFilters>
  );
};

export default Landing;
