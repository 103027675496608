import { CustomerInsights } from "./CustomerInsights";
import { CustomerCompetitorsAndTargetRegions } from "./CustomerCompetitorsAndTargetRegions";

export const KeyCustomers = ({ data }: any) => {
  return (
    <>
      <CustomerInsights
        title="Customer Segments"
        content={data.customer_segment}
      />

      {data?.customer_target_regions && (
        <CustomerCompetitorsAndTargetRegions
          title={"Customer Regions"}
          data={data?.customer_target_regions}
        />
      )}

      {data?.key_customers && (
        <CustomerInsights
          separator
          title="Key Customers"
          content={data.key_customers}
        />
      )}

      {data?.customer_competitors && (
        <CustomerCompetitorsAndTargetRegions
          title={"Competitors"}
          data={data?.customer_competitors}
        />
      )}
    </>
  );
};
