import React from "react";
import {
  Filter,
  FilterContent,
  FilterHeader,
  FilterHeading,
  FilterSectionContainer,
  ResetLink,
} from "../styles";
import { useSearchParams } from "react-router-dom";
import FilterItemBox from "./FilterItemBox";
import { getFilterTags } from "../helper";
import { ITagType } from "./types";
import {
  areArraysOfObjectsEqual,
  filterTypes,
  getFiltersForTypes,
  sortFilterByCheckedFilters,
} from "../../../Utils/helper";

interface FilterBoxProps {
  data: any;
  selectedFilter: { name: string; type: string }[];
  checkedFilters?: any;
}

const FilterBox = ({
  data,
  selectedFilter,
  checkedFilters,
}: FilterBoxProps) => {
  const [filters, setFilters] = React.useState<{ [key: string]: string[] }>({
    business_tags: [],
    tech_tags: [],
    customer_engagement_types: [],
    current_stage: [],
    level_funding_now: [],
    customer_location: [],
    customer_compliances: [],
  });
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [checked, setChecked] = React.useState<ITagType[]>([]);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [lsData, setLsData] = React.useState<string | null>("");

  React.useEffect(() => {
    let filterObj: any = {};
    Object.entries(filterTypes).map(([collectionType, field]: any) => {
      const tags = getFilterTags(data, collectionType);
      filterObj[collectionType] = tags;
    });
    setFilters({ ...filterObj });
    setChecked([...selectedFilter]);
    checkedFilters([...selectedFilter]);
  }, [data]);

  const handlePropFromChild = (open: boolean) => {
    setModalOpen(open);
  };

  const filterCheckedTags = (tagType: string) => {
    return checked.filter((tag: ITagType) => tag.type === tagType);
  };

  const handleOnChange = (e: any, checkbox: any, type: any) => {
    let tags = [...checked];
    tags = checked.filter((tag) => {
      return tag.name?.toLowerCase() !== checkbox.toLowerCase();
    });

    if (areArraysOfObjectsEqual(tags, checked)) {
      tags.push({ name: checkbox.toLowerCase(), type });
    }

    manageSearchParams(tags);
    setChecked(tags);
    checkedFilters(tags);
  };

  const manageSearchParams = (allFilters: any) => {
    const query = Object.entries(filterTypes)
      .map(([filterType, paramName]) => {
        const filters = getFiltersForTypes(allFilters, filterType);
        if (filters && filters.length > 0) {
          return paramName + "=" + filters.map((tag) => tag.name).join("|");
        }
        return null;
      })
      .filter((param) => param !== null);

    setSearchParams(query.join("&"));
  };

  const handleResetAll = () => {
    setSearchParams("");
    setChecked([]);
    checkedFilters([]);
  };

  return (
    <>
      <Filter>
        <FilterHeader>
          <FilterHeading title="Filter By" />
          <ResetLink onClick={handleResetAll}>Reset all</ResetLink>
        </FilterHeader>
        <FilterContent
          style={{
            overflowY: modalOpen ? "hidden" : "auto",
          }}
        >
          <FilterSectionContainer>
            {filters &&
              Object.keys(filterTypes)?.map((key: string) => {
                return (
                  sortFilterByCheckedFilters(
                    filters[key],
                    filterCheckedTags(key),
                    key
                  ).length > 0 && (
                    <FilterItemBox
                      key={key}
                      heading={filterTypes[key]}
                      type={key}
                      checkboxes={sortFilterByCheckedFilters(
                        filters[key],
                        filterCheckedTags(key),
                        key
                      )}
                      onChange={handleOnChange}
                      checked={filterCheckedTags(key)}
                      modalState={handlePropFromChild}
                    />
                  )
                );
              })}
          </FilterSectionContainer>
        </FilterContent>
      </Filter>
    </>
  );
};

export default FilterBox;
