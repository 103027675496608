import { Box } from "@mui/material";
import { KBAvatar, StakeHolderDesignation, StakeHolderEmail, StakeHolderListItem, StakeHolderName, NoInformationText, StakeHolderReportsTo } from "./styles";
import Icon from "../../Components/Icons/icons";

export const StakeholdersInfoTile = ({ stakeholder }: any) => {
    const reporterFullName = stakeholder?.reports_to;
    const wordPattern = /\S+/g;
    const nameArray = (reporterFullName || '').match(wordPattern) || [];
    const ReporterName = nameArray[0];

    return (
        <>
            {stakeholder !== null ? <StakeHolderListItem key={stakeholder.id}>
                <KBAvatar
                    sizes="32px"
                    alt={stakeholder?.name}
                    src={stakeholder?.profile_image || stakeholder?.name}
                />
                <Box paddingLeft={'0.75rem'} display={'flex'} flexDirection={'column'} flexGrow={'1'}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <StakeHolderName>{stakeholder?.name}</StakeHolderName>
                    </Box>
                    <StakeHolderDesignation>{stakeholder?.designation}</StakeHolderDesignation>
                    <StakeHolderEmail>{stakeholder?.email}</StakeHolderEmail>
                </Box>
            </StakeHolderListItem>
                :
                <StakeHolderListItem>
                    <Icon
                        size={32}
                        kind={'avatar'}
                    />
                    <Box paddingLeft={'0.75rem'} display={'flex'} flexDirection={'column'} flexGrow={'1'}>
                        <NoInformationText>{'Information not available'}</NoInformationText>
                    </Box>
                </StakeHolderListItem>
            }

        </>
    );
};
