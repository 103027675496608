import React from "react";
import {
  CustomerShowcaseContentWrapper,
  NoInformationText,
  ShowCaseButtonText,
  ShowCaseLink,
  ShowCaseLinkImage,
  ShowCaseTitle,
  ShowcaseWrapper,
  SidebarNoInformationText,
} from "./styles";
import Icon from "../../Components/Icons/icons";
import YouTubeThumbnail from "./YoutubeThumbnail";
import { Box } from "@mui/material";

const CustomerShowcase = ({ iconKind, contentType, data }: any) => {
  return (
    <ShowcaseWrapper>
      <ShowCaseTitle>
        <Icon size={24} kind={iconKind} />
        <ShowCaseButtonText>{contentType}</ShowCaseButtonText>
      </ShowCaseTitle>
      {data?.customer_showcases?.data.filter(
        (c: any) => c.attributes.content_type === contentType
      ).length === 0 ? (
        <SidebarNoInformationText>
          Information not available
        </SidebarNoInformationText>
      ) : (
        <>
          {data?.customer_showcases?.data
            ?.filter((c: any) => c.attributes?.content_type === contentType)
            ?.map((cst: any) => (
              <CustomerShowcaseContentWrapper key={cst.id}>
                {contentType === "Testimonials" ? (
                  <ShowCaseLinkImage
                    target="_blank"
                    className="breadcrumb-link"
                    underline="none"
                    href={cst.attributes?.link}
                  >
                    <YouTubeThumbnail url={cst.attributes?.link} />
                  </ShowCaseLinkImage>
                ) : (
                  <ShowCaseLink
                    target="_blank"
                    className="breadcrumb-link"
                    underline="none"
                    href={cst.attributes?.link}
                  >
                    <>
                      <Box
                        component={"span"}
                        lineHeight={1.15}
                        display={"block"}
                      >
                        {cst.attributes.title}
                      </Box>
                      {/* <Icon
                        size={18}
                        kind={"arrow-new-tab"}
                        className="mr-2 d-block icon"
                      /> */}
                    </>
                  </ShowCaseLink>
                )}
              </CustomerShowcaseContentWrapper>
            ))}
        </>
      )}
    </ShowcaseWrapper>
  );
};

export default CustomerShowcase;
