import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext, UserContextType } from "../Context/UserContext";

const ProtectedRoute = ({ children }: any) => {
  const { user } = useContext<UserContextType>(UserContext);
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return children;
};
export default ProtectedRoute;
