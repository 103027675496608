import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import RootApp from "./Containers/App";
import { theme } from "./theme";
import { useEffect, useState } from "react";
import { UserContext } from "./Context/UserContext";
import jwt_decode from "jwt-decode";

export const muiCache = createCache({
  key: "kb",
});

declare global {
  interface Window {
    dataLayer: any;
  }
}

interface IUser {
  unique_name?: string;
}

function App() {
  const loginUser = localStorage.getItem("accessToken") as string;
  const [user, setUser] = useState<boolean | null>(loginUser ? true : null);
  let decodedUser: IUser;
  if (loginUser) {
    decodedUser = jwt_decode(loginUser);
  }

  useEffect(() => {
    if (decodedUser) {
      window.dataLayer.push({
        event: "user_email",
        userEmail: decodedUser?.unique_name,
      });
    }
  }, [user]);

  return (
    <CacheProvider value={muiCache}>
      <Router>
        <UserContext.Provider value={{ user, setUser }}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <RootApp />
          </ThemeProvider>
        </UserContext.Provider>
      </Router>
    </CacheProvider>
  );
}

export default App;
