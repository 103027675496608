import React, { useEffect, useState } from "react";
import { BottomSection, PageWrapper, TableContainer } from "./styles";
import { Container } from "@mui/material";
import ResourceCounts from "./ResourceCounts";
import ResourceTable from "./ResourceTable";
import { getTableColumns } from "./helper";
import { useNavigate } from "react-router-dom";

const resourceObj: any = {
  "CASE STUDIES": "customer-case-studies",
  "EXTERNAL PUBLICATION": "customer-external-publications",
  "TL ARTICLES": "customer-tl-articles",
  "CLUTCH REVIEWS": "customer-clutch-reviews",
  "PITCHDECK BROCHURES": "customer-pitch-decks",
  TESTIMONIALS: "customer-testimonials",
};

const ResourceListing = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const query = urlParams.get("query") || "";
  const type = urlParams.get("type") || "";
  const [activeTab, setActiveTab] = useState(query);
  const navigate = useNavigate();

  if (type == "resources") {
  }
  const handleTabClick: any = (tabName: any) => {
    navigate(`/resources?query=${tabName}&type=resource`);
    setActiveTab(tabName);
  };

  return (
    <PageWrapper>
      <BottomSection>
        <Container maxWidth="lg">
          <ResourceCounts activeTab={activeTab} onClick={handleTabClick} />
        </Container>
        <TableContainer>
          <Container maxWidth="lg">
            <ResourceTable activeTab={activeTab} headerColumns={getTableColumns(activeTab) || []} />
          </Container>
        </TableContainer>
      </BottomSection>
    </PageWrapper>
  );
};

export default ResourceListing;
