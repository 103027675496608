import React, { useEffect, useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import { MarkdownComponent } from "../../Components/Markdown";
import {
  BlockHeading,
  ContributionHeadingBox,
  KbTab,
  KbTabItem,
  WowFactorsHeading,
  WowFactorsItemBox,
} from "./styles";
import { ICustomerDetails } from "./types";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TalenticaContribution = ({ data }: any) => {
  const [customerDetails, setCustomerDetails] = useState<ICustomerDetails>({});

  useEffect(() => {
    if (data) setCustomerDetails(data);
  }, [data]);

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 2, pb: 4 }}>
            <Typography component={"div"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const disabledWow = customerDetails?.customer_showcases?.data?.filter(
    (c: any) => c.attributes?.content_type === "Wow Factors"
  );

  return (
    <Box mt={"1.87rem"} mb={"1rem"} borderRadius={".625rem"}>
      <Box sx={{ width: "100%", borderBottom: "1px solid #DEDEED" }}>
        <ContributionHeadingBox>
          <BlockHeading title="Talentica Contribution" />
          <KbTab
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <KbTabItem
              label="Summary"
              {...a11yProps(0)}
              disabled={data?.talentica_contributions === null}
            />
            <KbTabItem
              label="Wow Factors"
              {...a11yProps(1)}
              disabled={disabledWow?.length === 0}
            />
            <KbTabItem
              label="Key Features"
              {...a11yProps(2)}
              disabled={data?.key_features === null}
            />
            <KbTabItem
              label="AI/ML"
              {...a11yProps(3)}
              disabled={data?.AI_ML === null || data?.AI_ML===undefined || !data?.AI_ML?.length}
            />
          </KbTab>
        </ContributionHeadingBox>
        <CustomTabPanel value={value} index={0}>
          {data?.talentica_contributions && (
            <MarkdownComponent>
              {data?.talentica_contributions}
            </MarkdownComponent>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <>
            {customerDetails?.customer_showcases?.data
              ?.filter((c: any) => c.attributes?.content_type === "Wow Factors")
              ?.map((cst: any) => (
                <WowFactorsItemBox
                  key={cst.attributes.title}
                  marginBottom={"1rem"}
                >
                  {cst.attributes.link !== null ? (
                    <Link
                      target="_blank"
                      underline="none"
                      sx={{
                        fontWeight: 500,
                        color: "#0B93DF",
                        fontSize: "0.875rem",
                        lineHeight: 1.149,
                        marginBottom: "1rem",
                        display: "inline-block",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      href={cst.attributes.link}
                    >
                      {cst.attributes.title}
                    </Link>
                  ) : (
                    <WowFactorsHeading title={cst.attributes.title} />
                  )}
                  <MarkdownComponent>
                    {cst.attributes.content}
                  </MarkdownComponent>
                </WowFactorsItemBox>
              ))}
          </>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <WowFactorsItemBox>
            {data?.key_features && (
              <MarkdownComponent>{data?.key_features}</MarkdownComponent>
            )}
          </WowFactorsItemBox>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          {data?.AI_ML && (
            <MarkdownComponent>{data?.AI_ML}</MarkdownComponent>
          )}
        </CustomTabPanel>
      </Box>
    </Box>
  );
};
