import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import DefaultLogo from "../../Assets/images/default_customer.png";
import Icon from "../../Components/Icons/icons";
import { getMonthString } from "../../Utils/helper";
import {

  CustomerInfoLabel,
  CustomerInfoValue,
  CustomerLogo,
  CustomerName,
  CustomerStatus,
  PageText,
  SectionHeading,
  TopSection,
  UndisclosedText,
  InfoIcon,
  CustomerLocation,

} from "./styles";
import { ICustomerDetails, IEngagementTypesAttr } from "./types";
import { Label } from "../Search/styles";


export const TopSectionComponent = ({ data }: any) => {
  const [customerDetails, setCustomerDetails] = useState<ICustomerDetails>({});

  useEffect(() => {
    if (data) setCustomerDetails(data[0]?.attributes);
  }, [data]);

  const getDate = (date: Date) => {
    const dd = new Date(date);
    const month = getMonthString(dd.getMonth());
    const yr = dd.getFullYear();
    return month + " " + yr;
  };



  return (
    <TopSection>
      <Container maxWidth={"lg"}>
        <Box display={"flex"}>
          <Box flexGrow={1} paddingRight={"1rem"} display={"flex"}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              marginRight={"1rem"}
            >
              <CustomerLogo
                src={
                  customerDetails?.logo?.data
                    ? customerDetails?.logo?.data?.attributes?.url
                    : DefaultLogo
                }
                alt="customer logo"
              />
              <CustomerStatus
                clickable={false}
                status={
                  customerDetails.current_status === "Active"
                    ? true
                    : false
                }
                label={customerDetails.current_status}
              />
            </Box>

            <Box>
              <CustomerName title={customerDetails?.name || ""} />
              {customerDetails?.strict_nda ? (
                <UndisclosedText
                  icon={
                    <InfoIcon
                      kind="outlined-info"
                      width={12}
                      height={17}
                    />
                  }
                  title={"Customer name not to be disclosed"}
                />
              ) : (
                <Label
                  sx={{ color: "#555", fontWeight: 400 }}
                  variant={"label"}
                  title={customerDetails?.nda_description}
                />
              )}
            </Box>
          </Box>
          <Box>
            <Box textAlign={"right"}>
              <CustomerInfoLabel
                align={"right"}
                title="Engagement Duration"
              />
              <CustomerInfoValue
                align={"right"}
                title={
                  `${getDate(customerDetails?.engagement_start_date!)} - ${customerDetails?.current_status==='Active' ?'Ongoing': customerDetails?.engagement_end_date===null|| undefined ? 'Ongoing' :getDate(customerDetails?.engagement_end_date!) }`
                }
              />
            </Box>
            <Box textAlign={"right"}>
              <CustomerInfoLabel
                align={"right"}
                title="Funding Updates"
              />
              <CustomerInfoValue
                align={"right"}
                title={`${customerDetails?.level_funding_started?.data?.attributes.name} to ${customerDetails?.level_funding_now?.data.attributes.name}`}
              />
            </Box>
          </Box>
        </Box>
        {customerDetails?.customer_location?.data?.attributes
          ?.location && (
            <Box
              display={"flex"}
              alignItems={"center"}
              marginTop={-1}
              mb="1.25rem"
            >
              <Icon kind="location" size={16} color='#555' />
              <CustomerLocation
                title={
                  customerDetails?.customer_location?.data?.attributes
                    ?.location
                }
              />
            </Box>
          )}
        <Box
          borderTop={"1px solid #DEDEED"}
          paddingTop={".78125rem"}
          marginBottom={"-1rem"}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Box>
            <SectionHeading title="Engagement type" />
            <PageText>
              {customerDetails?.customer_engagement_types?.data
                .map((type: IEngagementTypesAttr) => type?.attributes?.name)
                .join(", ")}
            </PageText>
          </Box>
          {customerDetails?.current_stage !== null && <Box textAlign={'right'}>
            <SectionHeading title="Current Stage" />
            <PageText>
              {customerDetails?.current_stage}
            </PageText>
          </Box>}
        </Box>
      </Container>
    </TopSection>
  );
};
