import React from "react";
import { BlockHeading, CustomerInsightContent, NoInformationText, PageText } from "./styles";

export const CustomerInsights = ({
  title,
  content,
  separator = false,
}: any) => {
  return (
    <>
      <BlockHeading title={title} />
      <CustomerInsightContent separator={separator}>
        {content ? <PageText>{content}</PageText> : <NoInformationText>Information not available</NoInformationText>}
      </CustomerInsightContent>
    </>
  );
};
