import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Box, TextField } from "@mui/material";

import { HeadingIcon, KBPaper, KBChips, KBTagsStack, KBTags, KbSearchBox, TagHeading } from "./styles";
import { CircularLoader } from "../CircularLoader";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Icon from "../Icons/icons";
import { NoDataFoundWrapper, NoResultsText } from "../../Containers/Search/styles";

const options = ["All", "Industry", "Technology", "Customer Name"];

interface SearchProps {
  className?: string;
  id: string;
  labelText: string;
  onlySearchIcon?: boolean;
  value?: string;
  focus?: boolean;
  techTags?: Array<Object>;
  businessTags?: Array<Object>;
  showDropdown?: boolean;
  isTechTagsLoading?: boolean;
  isBusinessTagsLoading?: boolean;
  techTagsError?: boolean;
  businessTagsError?: boolean;
}

const RenderTags = ({ tags, title, icon, variant, loading, type, hasError }: any) => {
  const navigate = useNavigate();
  const handleChip = (e: any, isResource?: boolean, query?: string) => {
    if (isResource) {
      navigate(`/resources?query=${query}&type=${type}`);
    } else {
      navigate(`/search?query=${e.target.innerText.trim()}&type=${type}`);
    }
  };

  return (
    <KBTags>
      {loading ? (
        <Box flexGrow={1} display={"flex"} alignItems={"center"}>
          <CircularLoader />
        </Box>
      ) : !hasError ? (
        <>
          <Box>
            <TagHeading icon={icon} title={title} />
          </Box>

          <KBTagsStack direction={"row"} spacing={1}>
            {type !== "resources" &&
              tags &&
              tags.map((tag: any) => {
                return <KBChips key={tag.id} variant={variant} onClick={handleChip} label={tag.attributes.name} />;
              })}
            {type === "tech_tags" && (
              <>
                <KBChips key={"Blockchain"} variant={"outlined"} onClick={handleChip} label={"Blockchain"} />
                <KBChips key={"Bigdata"} variant={"outlined"} onClick={handleChip} label={"Bigdata"} />
              </>
            )}
            {type === "resources" &&
              tags.map((tag: any,index:any) => {
                return (
                  <KBChips
                    key={index}
                    variant={variant}
                    onClick={(e: any) => handleChip(e, true, tag?.attributes?.query)}
                    label={tag.attributes.name}
                  />
                );
              })}
          </KBTagsStack>
        </>
      ) : (
        <NoDataFoundWrapper>
          <NoResultsText title="No results found." />
        </NoDataFoundWrapper>
      )}
    </KBTags>
  );
};

export const SearchBox = ({
  className,
  id,
  labelText,
  onlySearchIcon,
  value,
  focus,
  techTags,
  businessTags,
  showDropdown = true,
  isTechTagsLoading,
  isBusinessTagsLoading,
  techTagsError,
  businessTagsError,
}: SearchProps) => {
  const location = useLocation();
  const [isError, setIsError] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>(value && location.pathname === "/search" ? value : "");

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleOption = () => {
    switch (options[selectedIndex]) {
      case "All":
        return "all";
      case "Industry":
        return "business_tags";

      case "Technology":
        return "tech_tags";

      case "Customer Name":
        return "name";
      default:
        return "all";
    }
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const navigate = useNavigate();

  const handleSearch = (e: any) => {
    e.preventDefault();
    // if (searchQuery.length)
    if (!searchQuery.length) {
      setIsError(true);
      return;
    }
    navigate(`/search?query=${searchQuery.trim()}&type=${handleOption()}`);
  };

  useEffect(() => {
    location.pathname === "/search" && value ? setSearchQuery(value) : setSearchQuery("");
  }, [location.pathname, value]);

  const staticResourceData = [
    {
      attributes: {
        name: "Case Studies",
        query: "customer-case-studies",
      },
    },
    {
      attributes: {
        name: "External Publications",
        query: "customer-external-publications",
      },
    },
    {
      attributes: {
        name: "TL Articles",
        query: "customer-tl-articles",
      },
    },
    {
      attributes: {
        name: "Clutch reviews",
        query: "customer-clutch-reviews",
      },
    },
    {
      attributes: {
        name: "Pitchdeck Brochures",
        query: "customer-pitch-decks",
      },
    },
    {
      attributes: {
        name: "Testimonials",
        query: "customer-testimonials",
      },
    },
  ];

  return (
    <form onSubmit={handleSearch}>
      <KbSearchBox className={className}>
        <Box width={"100%"} position={"relative"}>
          <TextField
            sx={{
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: isError ? "#C30A07" : "",
              },
              "& .MuiFormHelperText-root": {
                position: "relative",
                zIndex: 1,
                backgroundColor: "#fff",
              },
            }}
            error={isError}
            helperText={isError ? "Please enter industry/technology/customer name  to continue" : ""}
            fullWidth
            id={id}
            label={labelText}
            size="small"
            variant="outlined"
            value={searchQuery}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
            autoFocus={focus}
          />
          {showDropdown && (
            <KBPaper
              sx={{
                marginTop: isError ? "-23px" : "",
                paddingTop: isError ? "10px" : "",
              }}
            >
              {/* {isError && (
                <ErrorMessage
                  variant={"error"}
                  title="Please enter industry/technology/customer name  to continue"
                />
              )} */}

              <RenderTags
                tags={businessTags}
                variant={"filled"}
                icon={<HeadingIcon width={17} height={18} kind="industry" />}
                title="Industry"
                loading={isBusinessTagsLoading}
                type="business_tags"
                hasError={businessTagsError}
              />
              <RenderTags
                tags={techTags}
                variant={"outlined"}
                icon={<HeadingIcon width={15} height={15} kind="technology" />}
                title="Technology"
                loading={isTechTagsLoading}
                type="tech_tags"
                hasError={techTagsError}
              />
              <RenderTags
                tags={staticResourceData}
                variant={"filled"}
                icon={<HeadingIcon width={17} height={20} kind="logo-icon" />}
                title="Talentica Resources"
                loading={false}
                type="resources"
                hasError={false}
              />
            </KBPaper>
          )}
        </Box>
        <React.Fragment>
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
            sx={{
              minWidth: "fit-content",
              boxShadow: "none",
            }}
          >
            {onlySearchIcon ? (
              <>
                <Button
                  startIcon={<Icon kind="search" size={24} />}
                  sx={{
                    height: "fit-content",
                    minHeight: 36,
                    flexGrow: 1,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    textTransform: "capitalize",
                    borderRight: "1px solid #fff",
                    borderColor: "#fff !important",
                    "&:disabled": {
                      backgroundColor: "#0B93DF55",
                    },
                    "& .MuiButton-startIcon": {
                      marginRight: 0,
                    },
                  }}
                  // disabled={!searchQuery.length}
                  onClick={handleSearch}
                />
                <Button
                  size="small"
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                  sx={{
                    height: "fit-content",
                    minHeight: 36,
                    backgroundColor: "#0B93DF",
                    boxShadow: "none",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <ArrowDropDownIcon sx={{ color: "" }} />
                </Button>
              </>
            ) : (
              <>
                <Button
                  startIcon={<Icon kind="search" size={24} />}
                  sx={{
                    height: "fit-content",
                    minHeight: 40,
                    flexGrow: 1,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    textTransform: "capitalize",
                    borderRight: "1px solid #fff",
                    borderColor: "#fff !important",
                  }}
                  // disabled={!searchQuery.length}
                  onClick={handleSearch}
                >
                  {options[selectedIndex]}
                </Button>
                <Button
                  sx={{ height: "fit-content", minHeight: 40 }}
                  size="small"
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </>
            )}
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => (
                        <MenuItem
                          sx={{ py: ".75rem" }}
                          key={option}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </React.Fragment>
      </KbSearchBox>
    </form>
  );
};
