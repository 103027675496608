export enum Type {
  TECH = 'tech_tags',
  BUSINESS = 'business_tags',
  ENGAGEMENT = 'customer_engagement_types',
  FUNDING = 'level_funding_now',
  LOCATION = 'customer_location',
  STAGE = 'current_stage',
  COMPLIANCES = 'customer_compliances'
}

export const CustomerShowcase = ['CaseStudy','ClutchReviews','Testimonials'];

