import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

interface CheckboxProps {
  label?: any;
  defaultChecked?: boolean;
  disabled?: boolean;
  checked?: boolean;
  name?: string;
  id?: string;
  labelPlacement?: 'top' | 'start' | 'bottom' | 'end';
  onChange?: any;
  indeterminate?: boolean;
  error?: boolean;
}
const StyledFormControlLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    lineHeight: '1.4285',
    fontSize: '0.875rem',
    fontWeight: 400,
    color: '#555555',
  },
  '&.MuiFormControlLabel-root': {
    gap: '0.5rem',
    margin: 0,
  },
});

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '&': {
    '&.MuiCheckbox-root': {
      border: '1px solid #dedeed',
      color: theme.palette.primary.contrastText,
      borderRadius: '0.125rem',
      padding: '0',
      '&:hover': {
        background: 'transparent',
      },
      '&.Mui-checked': {
        color: '#13B898',
      },
    },
  },
}));

export const KBCheckbox = ({
  label,
  defaultChecked,
  disabled,
  checked,
  labelPlacement,
  name,
  id,
  onChange,
  indeterminate,
  error = false,
  ...props
}: CheckboxProps) => {
  return (
    <StyledFormControlLabel
      control={
        <StyledCheckbox
          defaultChecked={defaultChecked}
          disabled={disabled}
          checked={checked}
          name={name}
          id={id}
          onChange={onChange}
          indeterminate={indeterminate}
          sx={{}}
          {...props}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};
