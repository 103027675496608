import React from "react";
import { Route, Routes } from "react-router-dom";
import Landing from "../Containers/Landing";
import { APP_PAGES } from "../pages";
import Search from "../Containers/Search";
import { CustomerDetails } from "../Containers/CustomerDetails";
import Login from "../Containers/Auth";
import ProtectedRoute from "./ProtectedRoute";
import ResourceListing from "../Containers/Resource";

const RouteConfig = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Landing />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route
        path={APP_PAGES.SEARCH}
        element={
          <ProtectedRoute>
            <Search />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_PAGES.CUSTOMER_DETAILS}
        element={
          <ProtectedRoute>
            <CustomerDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_PAGES.RESOURCES}
        element={
          <ProtectedRoute>
            <ResourceListing />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default RouteConfig;
