import { useEffect, useState } from "react";
import { NotificationContainer, NotificationHeader, NotificationSubHeader, } from "./styles";
import { ICustomerDetails } from "./types";
import CustomerShowcase from "./CustomerShowcase";
import StakeholderShowcase from "./StakeholderShowcase";

export const NotificationBox = ({ data }: any) => {
    const [customerDetails, setCustomerDetails] = useState<ICustomerDetails>({});

    useEffect(() => {
        if (data) setCustomerDetails(data[0]?.attributes);
    }, [data]);
    return (
        <>
            <NotificationContainer>
                <NotificationHeader>{'Customer Success Showcase'}</NotificationHeader>
                <CustomerShowcase iconKind="case-study" contentType="Case Study" data={customerDetails} />
                <CustomerShowcase iconKind="clutch-review" contentType="Clutch Reviews" data={customerDetails} />
                <CustomerShowcase iconKind="testimony" contentType="Testimonials" data={customerDetails} />

                <NotificationHeader>{'Customer Key Stakeholders'}</NotificationHeader>
                <StakeholderShowcase data={customerDetails?.customer_stakeholders} />
                <NotificationSubHeader>{'Talentica Key Stakeholders'}</NotificationSubHeader>
                <StakeholderShowcase data={customerDetails?.customer_talentica_stakeholders} />
            </NotificationContainer>
        </>
    );
};
