import { Box } from "@mui/material";

import {
  CalloutCard,
  CalloutCardContent,
  CalloutCardHeading,
  CalloutCardSubHeading,
  CalloutCardValue,
} from "./styles";

interface ISearchCounts {
  totalProjectsCount: number;
  activeProjectsCount: number;
  marqueeCustomersCount: number;
  query: string;
  active: string;
  onClick: Function;
  userSelectedFilters?: any;
}

export const SearchCounts = ({
  totalProjectsCount,
  activeProjectsCount,
  marqueeCustomersCount,
  query,
  onClick,
  active,
  userSelectedFilters,
}: ISearchCounts) => {
  return (
    <CalloutCard>
      <CalloutCardHeading title={query} />
      <Box display={"flex"} height={"100%"}>
        <CalloutCardContent
          sx={{
            bgcolor: active === "total" ? "#e2f3f0" : "transparent",
            borderBottomLeftRadius: active === "total" ? ".75rem" : 0,
            "&:hover": {
              bgcolor:
                active === "total" ? "#e2f3f0" : "rgb(222 222 237 / 50%)",
            },
          }}
          onClick={() => onClick("total")}
        >
          <CalloutCardValue title={totalProjectsCount} />
          <CalloutCardSubHeading
            sx={{ color: active === "total" ? "#0B93DF" : "#8f8f8f" }}
            title={"Total Products"}
          />
        </CalloutCardContent>
        <CalloutCardContent
          sx={{
            bgcolor: active === "active" ? "#e2f3f0" : "transparent",
            "&:hover": {
              bgcolor:
                active === "active" ? "#e2f3f0" : "rgb(222 222 237 / 50%)",
            },
          }}
          onClick={() => onClick("active")}
        >
          <CalloutCardValue title={activeProjectsCount} />
          <CalloutCardSubHeading
            sx={{ color: active === "active" ? "#0B93DF" : "#8f8f8f" }}
            title={"Active Products"}
          />
        </CalloutCardContent>
        {marqueeCustomersCount > 0 && (
          <CalloutCardContent
            sx={{
              bgcolor: active === "marquee" ? "#e2f3f0" : "transparent",
              borderBottomRightRadius: active === "marquee" ? ".75rem" : 0,
              borderRight: 0,
              "&:hover": {
                bgcolor:
                  active === "marquee" ? "#e2f3f0" : "rgb(222 222 237 / 50%)",
              },
            }}
            onClick={() => onClick("marquee")}
          >
            <CalloutCardValue title={marqueeCustomersCount} />
            <CalloutCardSubHeading
              sx={{
                color: active === "marquee" ? "#0B93DF" : "#8f8f8f",
              }}
              title={"Marquee Customers"}
            />
          </CalloutCardContent>
        )}
      </Box>
    </CalloutCard>
  );
};
