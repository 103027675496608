import React from "react";
import { styled } from "@mui/material/styles";
import Markdown from "markdown-to-jsx";

const KbMarkdownComponent = styled(Markdown)(({ theme }) => ({
  paddingTop: 0,
  paddingLeft: 0,
  lineHeight: 1.5,
  fontSize: "0.875rem",
  fontWeight: 400,
  marginTop: 0,
  marginBottom: 0,
  color: '#555',
  display: 'block',
  "& ul": {
    lineHeight: 1.87,
    fontWeight: 400,
    marginBottom: "1.25rem",
    marginTop: 0,
    paddingLeft: "1.8rem",
  },
  "& ol": {
    lineHeight: 1.87,
    fontWeight: 400,
    marginBottom: "1.25rem",
    marginTop: 0,
    paddingLeft: "1.8rem",
  },
  "& p": {
    marginTop: 0,
    marginBottom: "1.25rem",
    lineHeight: 1.5,
    fontWeight: 400,
    '&:last-child': {
      marginBottom: 0
    }
  },
  "& h1": {
    marginBottom: "1.25rem",
    marginTop: 0,
    fontWeight: 500,
    lineHeight: 1.87,
    fontSize: "1rem",
  },
  "& h2": {
    marginBottom: "1.25rem",
    marginTop: 0,
    fontWeight: 500,
    lineHeight: 1.87,
    fontSize: "1rem",
  },
  "& h3": {
    marginBottom: "1.25rem",
    marginTop: 0,
    fontWeight: 500,
    lineHeight: 1.87,
    fontSize: "1rem",
  },
  "& h4": {
    marginBottom: "1.25rem",
    marginTop: 0,
    fontWeight: 500,
    lineHeight: 1.87,
    fontSize: "1rem",
  },
  "& h5": {
    marginBottom: "1.25rem",
    marginTop: 0,
    fontWeight: 500,
    lineHeight: 1.87,
    fontSize: "1rem",
  },
  "& h6": {
    marginBottom: "1.25rem",
    marginTop: 0,
    fontWeight: 500,
    lineHeight: 1.87,
    fontSize: "1rem",
  },
  "& img": {
    width: "100%",
  },
  "& code": {
    width: "100%",
    display: "block",
    whiteSpace: "break-spaces",
  },
}));

interface MarkdownInterface {
  children: string;
  className?: string;
}

interface ImageProps {
  alt: string;
  src: string;
}

export const MarkdownComponent = ({
  children,
  className,
}: MarkdownInterface) => {
  const Image = (props: ImageProps) => {
    return (
      <img
        src={process.env.REACT_APP_STRAPI_BASE_URL + props.src}
        alt={props.alt}
      />
    );
  };

  const options = {
    overrides: {
      img: {
        component: Image,
      },
    },
  };

  return (
    <KbMarkdownComponent className={className} options={options}>
      {children}
    </KbMarkdownComponent>
  );
};
