import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { TableContainer, TableHeader, TableCell, TitleWithIcon, TableGridRow } from "./styles";
import Icon from "../../Components/Icons/icons";
import { getTableColumns } from "./helper";
import { getData } from "../../Utils/api";

import YouTubeThumbnail from "../CustomerDetails/YoutubeThumbnail";

interface ResourceTableProps {
  activeTab: string;
  headerColumns?: { name: string; width: number }[];
}

const ResourceTable: React.FC<ResourceTableProps> = ({ activeTab }) => {
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await getData(`/${activeTab}`);

      if (data?.length) {
        data
          .sort((a: any, b: any) => {
            return new Date(b.attributes.published_date).getTime() - new Date(a.attributes.published_date).getTime();
          })
          .forEach((item: any) => {});

        setPageData(data);
      }
    })();
  }, [activeTab]);
  const renderTableBasedOnActiveTab = () => {
    const tableColumns = getTableColumns(activeTab);

    if (tableColumns) {
      return (
        <TableContainer component={Box} id={activeTab} className={activeTab}>
          <Grid container className="table-grid">
            {tableColumns.map((column, index) => {
              return (
                <Grid item key={index} xs={column.width}>
                  <TableHeader>{column.name}</TableHeader>
                </Grid>
              );
            })}
          </Grid>

          {pageData.map((page, rowIndex) => {
            return (
              <TableGridRow key={rowIndex} container className="table-row">
                {tableColumns.map((col, colIndex) => {
                  if (col.field === "published_date") {
                    const date = new Date(page["attributes"]["published_date"]);
                    const day = date.getDate();
                    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
                    const year = date.getFullYear().toString().slice(-2);
                    const formattedDate = `${day}-${month}-${year}`;
                    return (
                      <Grid item xs={col.width} key={colIndex}>
                        <TableCell>{formattedDate}</TableCell>
                      </Grid>
                    );
                  } else if (colIndex === tableColumns.length - 1) {
                    if (activeTab === "customer-testimonials") {
                      return (
                        <Grid item xs={2} key={colIndex}>
                          <TableCell>
                            <a href={page["attributes"]["link"]} target="_blank" rel="noreferrer">
                              <YouTubeThumbnail url={page["attributes"]["link"]} />
                            </a>
                          </TableCell>
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid item xs={col.width} key={colIndex}>
                          <TableCell>
                            <TitleWithIcon>
                              <a href={page["attributes"]["link"]} target="_blank" rel="noreferrer">
                                <Icon kind="link" size={20} />
                              </a>
                            </TitleWithIcon>
                          </TableCell>
                        </Grid>
                      );
                    }
                  } else {
                    return (
                      <Grid item xs={col.width} key={colIndex}>
                        <TableCell>{page["attributes"][col.field]}</TableCell>
                      </Grid>
                    );
                  }
                })}
              </TableGridRow>
            );
          })}
        </TableContainer>
      );
    } else {
      return null;
    }
  };

  return renderTableBasedOnActiveTab();
};

export default ResourceTable;
