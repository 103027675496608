import React from "react";
import { Box } from "@mui/material";
import { BlockHeading, CustomerToolsAndCompliancesContent, NoInformationText, KeyCustomerTags } from "./styles";

export const ToolsTechnologies = ({ data }: any) => {
  return (
    <Box borderTop={'1px solid #DEDEED'} pt={"1rem"}>
            {
        <>
          <BlockHeading title="Compliances" />
          <CustomerToolsAndCompliancesContent>{
            data?.customer_compliances?.data.length !== 0 ?
              <Box display={"flex"} flexWrap={"wrap"}>
                {data?.customer_compliances &&
                  data?.customer_compliances?.data.map((customer: any) => {
                    return (
                      <KeyCustomerTags key={customer.id}>
                        {customer.attributes.name}
                      </KeyCustomerTags>
                    );
                  })}
              </Box>
              :
              <NoInformationText>Information not available</NoInformationText>
          }</CustomerToolsAndCompliancesContent>
        </>
      }
      {
        <>
          <BlockHeading title="Tools & Technologies" />
          <CustomerToolsAndCompliancesContent>
            {
              data?.tech_tags?.data.length !== 0 ?
                <Box display={"flex"} flexWrap={"wrap"}>
                  {data?.tech_tags &&
                    data?.tech_tags?.data.map((customer: any) => {
                      return (
                        <KeyCustomerTags key={customer.id}>
                          {customer.attributes.name}
                        </KeyCustomerTags>
                      );
                    })}
                </Box>
                :
                <NoInformationText>Information not available</NoInformationText>
            }
          </CustomerToolsAndCompliancesContent>
        </>
      }
    </Box>
  );
};
