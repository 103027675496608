import { Box, Grid } from "@mui/material";
import {
  CustomerEngagementTerm,
  CustomerKeyOutcomes,
  CustomerLogo,
  CustomerName,
  CustomerStatus,
  CustomerType,
  InfoIcon,
  Label,
  ListItem,
  UndisclosedText,
  CustomerLocation,
  CurrentStage,
  NDAApproval,
  TeamLabel,
  CustomerEngagementWrapper,
  CustomerStatusBox,
  CurrentStageBox,
  CurrentStageValue,
} from "../styles";

import DefaultLogo from "../../../Assets/images/default_customer.png";
import { getDuration } from "../../../Utils/helper";
import { useNavigate } from "react-router-dom";
import Icon from "../../../Components/Icons/icons";

export const Results = ({ customer }: any) => {
  const {
    name,
    customer_outcome,
    current_status,
    customer_engagement_types,
    engagement_start_date,
    engagement_end_date,
    strict_nda,
    nda_description,
    logo,
    customer_location,
    team_size,
    current_stage,
  } = customer;
  const getEngagementTerm = () => {
    const term = getDuration(engagement_start_date, engagement_end_date);
    const months = term.getMonth();
    const noOfYear = term.getFullYear() - 1970;
    if (noOfYear >= 1) {
      return (
        <>
          <Box display={"inline-flex"} sx={{ color: "#555" }}>
            {noOfYear} {noOfYear > 1 ? "Years" : "Year"} {months > 0 && months}{" "}
            {months > 1 ? "Months" : months === 1 ? "Month" : ""}
          </Box>
          &nbsp;
        </>
      );
    } else {
      return (
        <>
          <Box display={"inline-flex"} sx={{ color: "#555" }}>
            {months > 0 && months}{" "}
            {months > 1 ? "Months" : months === 1 ? "Month" : ""}
          </Box>
          &nbsp;
        </>
      );
    }
  };
  const navigate = useNavigate();
  return (
    <ListItem key={customer.id} onClick={() => navigate(`/${name}`)}>
      <Grid container spacing={2} mt={0}>
        <Grid item xs={4}>
          <Box px={2} display={"flex"} alignItems={"flex-start"}>
            <CustomerLogo
              src={logo?.data ? logo?.data?.attributes?.url : DefaultLogo}
              alt="customer logo"
            />
            <div>
              <CustomerName title={name || ""} />
              {/* <CustomerInfo title={synopsis || ""} /> */}
              {customer_location?.data?.attributes.location && (
                <Box display={"flex"} alignItems={"center"} marginTop={1}>
                  <Icon kind="location" size={16} color="#555" />
                  <CustomerLocation
                    title={customer_location?.data?.attributes.location}
                  />
                </Box>
              )}
            </div>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box px={2}>
            {customer_engagement_types?.data?.length > 0 && (
              <CustomerType
                variant={"label"}
                title={customer_engagement_types.data
                  .map((type: any) => type?.attributes?.name)
                  .join(", ")}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box px={2}>
            <CustomerKeyOutcomes title={customer_outcome || ""} />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box px={2} textAlign={"right"}>
            <CustomerEngagementTerm title={getEngagementTerm()} />
          </Box>
        </Grid>
      </Grid>
      <CustomerStatusBox>
        <Grid container spacing={2} mt={0}>
          <Grid item xs={4}>
            <Box flexGrow={1} display={"flex"}>
              <CustomerStatus
                status={current_status === "Active" ? true : false}
                label={current_status === "Not Active" ? "Inactive" : "Active"}
              />
              {customer_engagement_types?.data?.length > 0 && (
                <CustomerEngagementWrapper paddingRight={1}>
                  <Box display={"flex"} alignItems={"center"}>
                    <Icon kind="team" size={16} color="#555" />
                    <TeamLabel variant={"label"} title={"Talentica team:"} />
                  </Box>
                  &nbsp;
                  <TeamLabel variant={"label"} title={team_size || 0} />
                </CustomerEngagementWrapper>
              )}
            </Box>
          </Grid>
          <Grid item xs={4}>
            {current_stage !== null && (
              <CurrentStageBox>
                <Box display={"flex"}>
                  <CurrentStage variant={"label"} title={"Current Stage:"} />
                </Box>
                &nbsp;
                <CurrentStageValue display={"inline-flex"}>
                  {current_stage}
                </CurrentStageValue>
              </CurrentStageBox>
            )}
          </Grid>
          <Grid item xs={4}>
            <Box flexGrow={1} display={"flex"} justifyContent={"flex-end"}>
              {strict_nda ? (
                <UndisclosedText
                  icon={
                    <InfoIcon kind="outlined-info" width={12} height={17} />
                  }
                  title={"Customer name not to be disclosed"}
                />
              ) : (
                <NDAApproval variant={"label"} title={nda_description} />
              )}
            </Box>
          </Grid>
        </Grid>
      </CustomerStatusBox>
    </ListItem>
  );
};
