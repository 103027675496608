import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CalloutCardContent, CalloutCardValue, CalloutCardSubHeading } from "./styles";
import { getData } from "../../Utils/api";

const collections = [
  "customer-case-studies",
  "customer-external-publications",
  "customer-tl-articles",
  "customer-pitch-decks",
  "customer-clutch-reviews",
  "customer-testimonials",
];

const ResourceCounts = ({ activeTab, onClick }: { activeTab: string; onClick: (tab: string) => void }) => {
  const [activeTabState, setActiveTab] = useState<string | undefined>(activeTab);
  const [collectionCounts, setCollectionCounts] = useState<any[]>([]);

  useEffect(() => {
    const fetchCollectionCounts = async () => {
      const counts = await Promise.all(
        collections.map(async (collection) => {
          const data = await getData(`/${collection}`);
          return { name: collection, count: data.length };
        })
      );

      setCollectionCounts(counts);
    };

    fetchCollectionCounts();
  }, []);

  const tabConstants = [
    { name: "customer-case-studies", subHeading: "Case Studies" },
    { name: "customer-external-publications", subHeading: "External Publications" },
    { name: "customer-tl-articles", subHeading: "TL Articles" },
    { name: "customer-pitch-decks", subHeading: "Pitchdeck & Brochures" },
    { name: "customer-clutch-reviews", subHeading: "Clutch Reviews" },
    { name: "customer-testimonials", subHeading: "Testimonials" },
  ];

  const resourceData = collectionCounts.map((apiData) => {
    const matchingTab = tabConstants.find((tab) => tab.name === apiData.name);
    return { ...apiData, subHeading: matchingTab?.subHeading };
  });

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    onClick(tabName);
  };

  return (
    <Box>
      <Box display="flex" mb="2.188rem">
        {resourceData.map((tab, index) => (
          <CalloutCardContent
            key={index}
            className={activeTabState === tab.name ? "active" : ""}
            onClick={() => handleTabClick(tab.name)}
          >
            <CalloutCardValue title={tab.count.toString().padStart(2, "0")} />
            <CalloutCardSubHeading title={tab.subHeading} />
          </CalloutCardContent>
        ))}
      </Box>
    </Box>
  );
};

export default ResourceCounts;
