import React from "react";
import { ShowcaseWrapper } from "./styles";
import { Stack } from "@mui/material";
import { StakeholdersInfoTile } from "./SteakholdersInfoTile";

const StakeholderShowcase = ({ data }: any) => {

    return (
        <ShowcaseWrapper>
            <Stack>
                <>
                    {data?.data.length !== 0
                        ?
                        data?.data?.filter((c: any) => c.attributes)?.map((cst: any) => (
                            <StakeholdersInfoTile key={cst.attributes?.name} stakeholder={cst.attributes} />
                        ))
                        :
                        <StakeholdersInfoTile stakeholder={null} />
                    }
                </>

            </Stack>
        </ShowcaseWrapper>
    );
};

export default StakeholderShowcase;
