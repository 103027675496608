import {
  Box,
  Chip,
  FormControl,
  List,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Icon from "../Icons/icons";
import { Heading } from "../Heading";

export const KbSearchBox = styled(FormControl)(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  "& .MuiInputBase-root": {
    borderRadius: "0.25rem 0 0 0.25rem",
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.875rem",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(.875rem, -.5625rem) scale(0.85)",
  },
  "& .searchBtn": {
    background: theme.palette.primary.main,
    textTransform: "capitalize",
    borderRadius: "0 0.25rem 0.25rem 0",
    fontSize: "1.25rem",
    fontWeight: 400,
    padding: "0.125rem 1.563rem",
    minWidth: "auto",
  },
}));

export const KBPaper = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: "100%",
}));

export const KBTagsStack = styled(Stack)(({ theme }) => ({
  flexWrap: "wrap",
  marginInlineStart: "-1rem",
  marginBlockEnd: "-1rem",
  "&>:not(style)~:not(style)": {
    margin: "unset",
    marginInlineStart: "1rem",
    marginBlockEnd: "1rem",
  },
}));

export const KBTags = styled(Box)(({ theme }) => ({
  padding: "1.25rem 0.9375rem",
  borderBottom: "1px solid #DBDBDB",
  maxHeight:"12.5rem",
  minHeight:"12.5rem",
  overflow:"auto",
  display:"flex",
  flexDirection:"column",
  '&:last-child':{
    borderBottom: 0
  }
}));

export const KBChips = styled(Chip)(({ theme }) => ({
  marginInlineStart: "1rem",
  marginBlockEnd: "1rem",
  textTransform: "uppercase",
}));

export const KBBusinessTags = styled(Box)(({ theme }) => ({
  paddingBlock: "1.25rem",
}));

export const KBList = styled(List)(({theme})=>({
  maxHeight: "12.5rem",
  overflowY: "auto"
}))


export const KBListItem = styled(ListItemText)(()=>({
  textTransform: "capitalize",
  color: '#343434'
}))

export const KBListItemIcon = styled(ListItemIcon)(()=>({
  minWidth: "2.5rem"
}))

export const HeadingIcon = styled(Icon)(()=>({
  marginRight: ".5rem"
}))

export const TagHeading = styled(Heading)(()=>({
  fontSize:".875rem",
  lineHeight: 1.285,
  marginBottom: '1.125rem',
  display: "flex"
}))

export const ErrorMessage = styled(Heading)(()=>({
  color: "#C30A07",
  fontSize: ".75rem",
  fontWeight: 400,
  padding:".9375rem"
}))