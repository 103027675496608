import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IApiResponse, useFetch } from "../../Utils/hooks/useFetch";
import { Box, Container, Grid } from "@mui/material";
import Icon from "../../Components/Icons/icons";
import {
  BottomSection,
  NoResultsText,
  QueryWord,
  PageWrapper,
  NoDataFoundWrapper,
  KeyOutcomes,
  KeyOutcomesLabel,
  KeyOutcomesValue,
} from "./styles";
import { CircularLoader } from "../../Components/CircularLoader";
import { ICustomerDetails } from "./types";

import { TopSectionComponent } from "./TopSection";
import { TalenticaContribution } from "./TalenticaContribution";
import { ToolsTechnologies } from "./ToolsTechnologies";
import { KeyCustomers } from "./KeyCustomers";
import { Overview } from "./Overview";
import { NotificationBox } from "./NotificationContainer";
import { TeamSize } from "./TeamSize";

export const CustomerDetails = () => {
  const [customerDetails, setCustomerDetails] = useState<ICustomerDetails>({});
  const { name } = useParams();
  const { data, loading }: IApiResponse = useFetch(
    `/customers/?filters[name]$eq=${name}&&populate=*`
  );
  useEffect(() => {
    if (data) setCustomerDetails(data[0]?.attributes);
  }, [data]);

  return (
    <PageWrapper>
      {loading ? (
        <Box flexGrow={1} display={"flex"}>
          <CircularLoader size={80} />
        </Box>
      ) : customerDetails ? (
        <>
          <TopSectionComponent data={data} />
          <BottomSection>
            <Container maxWidth="lg">
              <Grid container spacing={2}>
                <Grid item xs={9} paddingRight={"1rem"}>
                  <Overview data={customerDetails} />
                  <KeyOutcomes>
                    <KeyOutcomesLabel title="Key Outcomes:" />
                    &nbsp;
                    <KeyOutcomesValue
                      title={customerDetails?.customer_outcome || ""}
                    />
                  </KeyOutcomes>
                  <TalenticaContribution data={customerDetails} />
                  <TeamSize data={customerDetails} />
                  <KeyCustomers data={customerDetails} />
                  <ToolsTechnologies data={customerDetails} />
                </Grid>
                <Grid paddingLeft={"1.87rem"} item xs={3}>
                  <NotificationBox data={data} />
                </Grid>
              </Grid>
            </Container>
          </BottomSection>
        </>
      ) : (
        <NoDataFoundWrapper>
          <Icon width={133} height={90} kind="no-data-found" />
          <NoResultsText title="No results found for" />
          <QueryWord title={`\`${name}\``} />
        </NoDataFoundWrapper>
      )}
    </PageWrapper>
  );
};
